import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import React from "react"
import { baiduSite, check } from '../components/census'

import { BannerCard } from './Articles'

import './common.css'
import './media.css'
import './bootstrap.min.css'

const Header = ({ siteTitle }) => {
  const [ scrollFlag, setScrollFlag ] = React.useState(false)
  const [ agent, setAgent ] = React.useState(true)
  const [ activeNav, setActiveNav ] = React.useState(true)

  const articles = useStaticQuery(
    graphql`
    query{
      allStrapiArticles (sort: {fields: updated_at, order: DESC}) {
        edges {
          node {
            title,
            slug,
            recomment,
            articlesTag,
            created_at,
            updated_at,
            content,
            image {
              childImageSharp {
                fixed(width: 1600, height: 800) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            },
            description
          }
        }
      }
    }`
  )

  const articlesData = articles?.allStrapiArticles?.edges
  const data = articlesData?.length ? articlesData.map(item => item?.node) : []

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      setAgent(check())
    }
    baiduSite()

    // fetch(`${apiURL}/articles?_sort=updated_at:DESC`, {
    //   headers: { 'content-type': 'application/json' },
    // })
    //   .then((res) => {
    //     if (res.status == 200) {
    //       return res.json()
    //     }
    //   })
    //   .then(json => {
    //     // let newJson = json?.length ? json.filter(item => item.title&& item.title.includes(articlesTag) ).splice(0, 3) : []
    //     setData(json) 
    //   })
    //   .catch(err => console.log(err))
  },  [])



  let arr = [ '数据采集与控制引擎', '可视化组态引擎', '数据分析引擎', '业务流引擎', '二次开发引擎' ]

  return (
    <div className="header-fix">
      <Navbar collapseOnSelect expand="lg" variant="dark" className="header-nav" style={{ width: '100%' }}>
        <Navbar.Brand>
          <Link to="/" className="nav-title" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={require('./../images/logo.svg')} alt="" style={{ width: '32px' }} />
            <span className="airiot-title" style={ scrollFlag ? { backgroundImage: `url(${require("./../images/black.svg")})` } : null }></span>
            {/* <img alt="" src={require('./../images/logo_home.png')} width="120" height="24" className="d-inline-block align-top" />{' '} */}
            {/* <span dangerouslySetInnerHTML={{ __html: widthHide ? 'AIRIOT' : __htmlsiteTitle }}></span> */}
            {/* <img src={require('./../images/newlogo.png')} alt="" /> */}
            {/* { widthHide ? <><span class="logo" style={{ color: '#32D3FF' }}>A</span>IR<span class="logo"  style={{ color: '#32D3FF' }}>I</span>OT</> : <><span class="logo" style={{ color: '#32D3FF' }}>A</span>IR<span class="logo"  style={{ color: '#32D3FF' }}>I</span>OT 让物联网项目更简单</> } */}
            <span className="airiot-logo" style={{ marginLeft: '.6rem' }}>让智慧物联项目更简单</span>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="aacc"    
          // onMouseOver={() => {
          //       document.getElementsByClassName('nav_restructure')[0].style.display = 'none';
          //       document.getElementsByClassName('nav_restructure')[0].style.opacity = 0
          //       setActiveNav('')
          //     }}
          >
          <span className="open dropdown dropdown-nav engine-dropdown" style={{ alignItems: 'center', textDecoration: 'none' }}
              onMouseOver={() => {
                document.getElementsByClassName('nav_restructure')[0].style.display = 'block';
                document.getElementsByClassName('nav_restructure')[0].style.opacity = 1
                document.getElementsByClassName('nav_restructure')[0].style.top = '4.5rem'

                setActiveNav('产品')
              }}
              // onMouseOut={() => {
              //   document.getElementsByClassName('nav_restructure')[0].style.display = 'none'
              //   document.getElementsByClassName('nav_restructure')[0].style.opacity = 0
              // }}
            >
              <span class="dropdown-toggle nav-btn nav-link" style={{ textDecoration: 'none' }}>
                <span className="caret">产品</span>
              </span>
            </span>
            {/* <span className="open dropdown dropdown-nav engine-dropdown" style={{ alignItems: 'center', textDecoration: 'none' }}
              onMouseOver={() => {
                document.getElementsByClassName('dropdown-menu-engine')[0].style.display = 'block';
              }}
              onMouseOut={() => {
                document.getElementsByClassName('dropdown-menu-engine')[0].style.display = 'none'
              }}
            >
              <span class="dropdown-toggle nav-btn nav-link" style={{ textDecoration: 'none', color: agent && scrollFlag && '#fff' }}>
                <span className="caret">产品</span>
              </span>
              <ul class="dropdown-menu-engine" style={{ textAlign: agent ? 'center' : 'left'}}
                onMouseOut={() => {
                document.getElementsByClassName('dropdown-menu-engine')[0].style.display = 'none'
              }}>
                <div className="drop-menu-div"></div>
                {
                  arr.map(item => {
                    return  <li className="drop-menu-item">
                    <Link onClick={()=> { document.getElementsByClassName('dropdown-menu-engine')[0].style.display = 'none' }} 
                      activeClassName="active" to={`/engine?name=${item}`}>{item}</Link>
                  </li>
                  })
                }
                <div className="drop-menu-div-bottom"></div>
              </ul> 
            </span>  */}
            
            <Link className="nav-btn nav-link nav-about" activeClassName="active" //to="/articles" 
              onMouseOver={() => {
                document.getElementsByClassName('nav_restructure')[0].style.display = 'block';
                document.getElementsByClassName('nav_restructure')[0].style.opacity = 1
                document.getElementsByClassName('nav_restructure')[0].style.top = '4.5rem'

                setActiveNav('案例')
              }}>案例</Link>

            {/* <span className="open dropdown dropdown-nav engine-dropdown" style={{ alignItems: 'center', textDecoration: 'none', color: agent && scrollFlag && '#fff' }}
              onMouseOver={() => {
                document.getElementsByClassName('dropdown-menu-al')[0].style.display = 'block';
              }}
              onMouseOut={() => {
                document.getElementsByClassName('dropdown-menu-al')[0].style.display = 'none'
              }}
            >
              <span class="dropdown-toggle nav-btn nav-link" style={{ textDecoration: 'none', color: agent && scrollFlag && '#fff' }}>
                <span className="caret">案例</span>
              </span>
              <ul class="dropdown-menu-al" style={{ textAlign: agent ? 'center' : 'left'}}
                onMouseOut={() => {
                  document.getElementsByClassName('dropdown-menu-al')[0].style.display = 'none'
                }}
                >
                <BannerCard  activeNav={activeNav} data={data} />
              </ul>
            </span>   */}


            <Link 
             onMouseOver={() => {
                document.getElementsByClassName('dropdown-menu')[0].style.display = 'none'

                document.getElementsByClassName('nav_restructure')[0].style.display = 'block';
                document.getElementsByClassName('nav_restructure')[0].style.opacity = 1
                document.getElementsByClassName('nav_restructure')[0].style.top = '4.5rem'

                setActiveNav('下载')

              }} className="nav-btn nav-link" activeClassName="active" >下载</Link>


            <Link className="nav-btn nav-link nav-about"
              onMouseOver={() => {
                document.getElementsByClassName('nav_restructure')[0].style.display = 'none';
                document.getElementsByClassName('nav_restructure')[0].style.opacity = 0
                setActiveNav('')
              }}
             id="about" activeClassName="active" href="/ecology">合作</Link>

            {/* <Link onMouseOver={() => {
                document.getElementsByClassName('dropdown-menu')[0].style.display = 'none'
              }} className="nav-btn nav-link nav-about" id="ecology" activeClassName="ecology" href="/ecology" style={{ color: agent && scrollFlag && '#fff' }}>AIRIOT生态</Link> */}

            <span className="open dropdown dropdown-nav studys-dropdown" style={{ alignItems: 'center', textDecoration: 'none' }}
              onMouseOver={() => {
                document.getElementsByClassName('dropdown-menu')[0].style.display = 'block';
              }}
              onMouseOut={() => {
                document.getElementsByClassName('dropdown-menu')[0].style.display = 'none'
              }}
            >
              <span class="dropdown-toggle nav-btn nav-link" style={{ textDecoration: 'none' }}>
                <span className="caret">资源中心</span>
              </span>
              <ul class="dropdown-menu" style={{ textAlign: agent ? 'center' : 'left'}}
                onMouseOut={() => {
                  document.getElementsByClassName('dropdown-menu')[0].style.display = 'none'
                }}>
                <div className="drop-menu-div"></div>
                <li className="drop-menu-item">
                  <a className="first-a"  href="https://appxow65u356815.h5.xiaoeknow.com/p/course/video/v_64bf7acee4b007b201aa0391" target="_black">快速上手</a>
                </li>
                <li className="drop-menu-item"  onMouseOut={() => {
                    document.getElementsByClassName('drop-menu-module')[0].style.display = 'none'
                  }}>
                  <a className="first-a"  href="https://appxow65u356815.pc.xiaoe-tech.com" target="_black">空中课堂</a>
                </li>
                {/* <li className="drop-menu-item"  onMouseOut={() => {
                    document.getElementsByClassName('drop-menu-module')[0].style.display = 'none'
                  }}>
                  <Link className="first-a"  to="/competition" target="_black">创新大赛</Link>
                </li> */}
                <li className="drop-menu-item drop-menu-helpword"  onMouseEnter={() => {
                  document.getElementsByClassName('drop-menu-module')[0].style.display = 'block';
                }}>
                  <a className="first-a" href="#">帮助文档</a>
                  <div className="drop-menu-module" onMouseOut={() => {
                    // document.getElementsByClassName('drop-menu-module')[0].style.display = 'none'
                  }}>
                    <div className="drop-menu-div"></div>
                    <li className="drop-menu-item">
                      <a  href="https://docs.airiot.link/" target="_black">4.0</a>
                    </li> 
                    <li className="drop-menu-item">
                      <a  href="https://r8ja3mlg7i.feishu.cn/wiki/G61hwOGB4iVCARkxAcOcPODmnIQ" target="_black">3.0</a>
                    </li>
                    <div className="drop-menu-div-bottom"></div>
                  </div>
                </li>
                <li className="drop-menu-item"  
                  onMouseEnter={() => {
                    document.getElementsByClassName('drop-menu-module')[0].style.display = 'none';
                  }}>
                  <a className="first-a" href="https://docs.airiot.link/category/%E5%BC%80%E5%8F%91%E6%89%8B%E5%86%8C/" target="_black">开发文档</a>
                </li>
                <li className="drop-menu-item">
                  <Link className="first-a"  to="/repos/front" target="_black">模块列表</Link>
                </li>
                <div className="drop-menu-div-bottom"></div>
              </ul>
            </span> 

         

            {/* <span onMouseOver={() => {
                document.getElementsByClassName('dropdown-menu')[0].style.display = 'none'
              }} onClick={() => window.open('https://edu.airiot.tech/') }  className="nav-btn nav-link" activeClassName="active"
             >大学计划</span> */}


            <Link className="nav-btn nav-link nav-about" id="about" activeClassName="active" to="/about">关于我们</Link>

          </Nav>
          <span className="nav-btn nav-link nav-span" style={{ paddingTop: '.4rem'}}>
            <span className="icon-action">
              <svg style={{ color: 'rgb(98, 100, 103)' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
              </svg>
            </span>
            <a href="tel:010-84860985" style={{ marginLeft: '0.4375rem', color: 'rgb(98, 100, 103)', fontSize: '0.875rem', textDecoration: 'none' }}>010-84860985</a></span>
        </Navbar.Collapse>
      </Navbar>
      <BannerCard  activeNav={activeNav} data={data} setActiveNav={setActiveNav}/>
    </div>
  )
}

export default Header
